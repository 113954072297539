@charset "utf-8";

// Fonts --------------------------------- //
@font-face {
    font-family: "kt_smarnspecialregular";
    src: url("./assets/fonts/kt_smarnspecial-webfont.eot");
    src: url("./assets/fonts/kt_smarnspecial-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/kt_smarnspecial-webfont.woff2") format("woff2"),
      url("./assets/fonts/kt_smarnspecial-webfont.woff") format("woff"),
      url("./assets/fonts/kt_smarnspecial-webfont.ttf") format("truetype"),
      url("./assets/fonts/kt_smarnspecial-webfont.svg#kt_smarnspecialregular")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "db_heaventbold";
    src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot");
    src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff2") format("woff2"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff") format("woff"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.ttf") format("truetype"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.svg#db_heaventbold")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "db_heaventmed";
    src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot");
    src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/db_heavent_med_v3.2-webfont.woff2") format("woff2"),
      url("./assets/fonts/db_heavent_med_v3.2-webfont.woff") format("woff"),
      url("./assets/fonts/db_heavent_med_v3.2-webfont.ttf") format("truetype"),
      url("./assets/fonts/db_heavent_med_v3.2-webfont.svg#db_heaventmed")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "db_heaventregular";
    src: url("./assets/fonts/db_heavent_v3.2-webfont.eot");
    src: url("./assets/fonts/db_heavent_v3.2-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/db_heavent_v3.2-webfont.woff2") format("woff2"),
      url("./assets/fonts/db_heavent_v3.2-webfont.woff") format("woff"),
      url("./assets/fonts/db_heavent_v3.2-webfont.ttf") format("truetype"),
      url("./assets/fonts/db_heavent_v3.2-webfont.svg#db_heaventregular")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "db_heaventlight";
    src: url("./assets/fonts/db_heavent_li_v3.2-webfont.eot");
    src: url("./assets/fonts/db_heavent_li_v3.2-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/db_heavent_li_v3.2-webfont.woff2") format("woff2"),
      url("./assets/fonts/db_heavent_li_v3.2-webfont.woff") format("woff"),
      url("./assets/fonts/db_heavent_li_v3.2-webfont.ttf") format("truetype"),
      url("./assets/fonts/db_heavent_li_v3.2-webfont.svg#db_heaventlight")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "db_heaventthin";
    src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot");
    src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff2") format("woff2"),
      url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff") format("woff"),
      url("./assets/fonts/db_heavent_thin_v3.2-webfont.ttf") format("truetype"),
      url("./assets/fonts/db_heavent_thin_v3.2-webfont.svg#db_heaventthin")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }

// Reset
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
// HTML5 display-role reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body,
html {
  // font-size: 125%; // For "db_heaventmed" : 20px
  line-height: 1;

  $breakpoint-tablet: 768px;
  @media (min-width: $breakpoint-tablet) {
    font-size: 175%; // For "db_heaventmed" : 28px
  }
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  display: inline-block;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
:after,
:before {
  box-sizing: border-box;
}

a, p, span, h1, h2, h3, h4, h5, h6 {
    font-family: 'db_heaventregular';
    font-size: 1.4rem;
}

// Color ---------------------------------//
$fill-bg-content: #f1f5f6;
$fill-blue: #007bfe;
$fill-blue-header: #4b75c0;
$fill-blue-table: #037afb;
$fill-dark-grey: #252526;
$fill-light-grey: #dddddd;
$fill-grey-font: #939ca9;
$fill-red: #d80012;
$fill-white: #fff;
$fill-black: #000;

$shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);


// Main ----------------------------------//
.App {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  font-family: "db_heaventregular";
  font-size: 1.4rem;

  .logo-box {
    img {
        width: 100%;
    }
    .description {
        padding: 0 20px;
        p {
            padding: 10px 14px;
            margin-top: -10%;
            border-radius: 10px;
            border: 1px solid #eee;
            background-color: #fff;
            display: inline-block;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
        }
    }
  }

  .grandprize-box {
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        p {
            color: #7a7a7a;
            font-family: "db_heaventregular";
            font-size: 1.4rem;
            margin-right: 10px;
            text-decoration: underline;
            text-decoration-color: $fill-light-grey;
        }
        img {
            width: 26px;
        }
    }
  }

  .jigsaw-box {
    padding: 4.5% 6% 6%;
    background-image: url(./assets/img/frame.png);
    background-size: 100%;
    background-origin: padding-box;
    background-repeat: no-repeat;
    background-position: center 0;
    .jigsaw-bg {
        background-size: 100%;
    }
    .container {
      display: flex;
      border-top: 1px solid #7A6E65;
      overflow: hidden;
      &:last-child {
        border-bottom: 1px solid #7A6E65;
      }
      .item {
          display: inline-block;
          position: relative;
          border-right: 1px solid #7A6E65;
          background-repeat: no-repeat;

          // .itemAnimate {
          //   opacity: 1;
          //   -webkit-transform: translateY(0);
          //   transition: all .5s ease-in .5s;
          //   &.active {
          //     opacity: .5;
          //     -webkit-transform: translateY(-100px);
          //   }
          // }
          
          &:first-child {
            border-left: 1px solid #7A6E65;
          }
          img {
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
          }
      }

      &._4p {
          img {
            width: 24%;
          }
          .item {
              width: 50%;
              &._1, #itemAnimate1 {
              .front, .back {
                  background-position: 0 0%;
                }
              }
              &._2, #itemAnimate2 {
              .front, .back {
                  background-position: 100% 0;
                }
              }
              &._3, #itemAnimate3 {
              .front, .back {
                  background-position: 0 100%;
                }
              }
              &._4, #itemAnimate4 {
              .front, .back {
                  background-position: 100% 100%;
                } 
              }
          }
      }

      &._8p {
        img {
          width: 34%;
        }
        .item {
            width: 25%;
            &._1, #itemAnimate1 {
              .front, .back {
                background-position: 0 0%;
              }
            }
            &._2, #itemAnimate2 {
              .front, .back {
                background-position: 33.33% 0;
              }
            }
            &._3, #itemAnimate3 {
              .front, .back {
                background-position: 66.33% 0;
              }
            }
            &._4, #itemAnimate4 {
              .front, .back {
                background-position: 100% 0;
              }
            }
            &._5, #itemAnimate5 {
              .front, .back {
              background-position: 0 100%;
              }
            }
            &._6, #itemAnimate6 {
              .front, .back {
                background-position: 33.33% 100% ;
              }
            }
            &._7, #itemAnimate7 {
              .front, .back {
                background-position: 66.33% 100%;
              }
            }
            &._8, #itemAnimate8 {
              .front, .back {
                background-position: 100% 100%;
              }
            }
        }
      }

      &._12p {
        img {
          width: 44%;
        }
        .item {
            width: 25%;
            &._1, #itemAnimate1 {
              .front, .back {
                background-position: 0 0%;
              }
            }
            &._2, #itemAnimate2 {
              .front, .back {
                background-position: 33.66% 0;
              }
            }
            &._3, #itemAnimate3 {
              .front, .back {
                background-position: 66.66% 0;
              }
            }
            &._4, #itemAnimate4 {
              .front, .back {
                background-position: 100% 0;
              }
            }
            &._5, #itemAnimate5 {
              .front, .back {
              background-position: 0 50%;
              }
          }
            &._6, #itemAnimate6 {
              .front, .back {
                background-position: 33.66% 50% ;
              }
            }
            &._7, #itemAnimate7 {
              .front, .back {
                background-position: 66.66% 50%;
              }
            }
            &._8, #itemAnimate8 {
              .front, .back {
                background-position: 100% 50%;
              }
            }
            &._9, #itemAnimate9 {
              .front, .back {
                background-position: 0 100%;
              }
            }
            &._10, #itemAnimate10 {
              .front, .back {
              background-position: 33.66% 100%;
              }
          }
            &._11, #itemAnimate11 {
              .front, .back {
                background-position: 66.66% 100% ;
              }
            }
            &._12, #itemAnimate12 {
              .front, .back {
                background-position: 100% 100%;
              }
            }
        }
      }
    }

  }

}



.flip-card {
  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transition: transform 1.8s;
    transition: transform 1.8s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    .front,
    .back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    // .front {
      // background-color: #bbb;
    // }
    .back {
      background-color: teal;
      // color: white;
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
    }
  }
  &.active {
    .inner {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
    }
  }
}

// Loading & Error & Main ------------------------------//
.App-loading,
.App-error,
.App-main {
  display: flex;
  width: 100vw;
  height: 100vh;
  p {
    font-size: 30px;
    flex: 1;
    align-self: center;
    padding: 0 20px;
  }
}


// Modal ----------------------------------------------------------//
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
._modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto 0;
  background-color: $fill-white;
  border-radius: 10px;
  // -webkit-transform: translate(0, 5%);
  width: 90%;

  img {
    width: 100%;
  }

  &.modal-error {
    width: 80%;

    ._info-modal {
      border-radius: 10px;
    }
  }

  ._info-modal{
    text-align: left;
    padding: 30px 20px;
    position: relative;
  }

  h1 {
    font-size: 2rem;
    font-family: 'db_heaventmed';
    text-align: center;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 1.6rem;
    font-family: 'db_heaventmed';
    margin-top: 10px;
  }

  .modal-subheader {
    font-size: 1.6rem;
    font-family: 'db_heaventmed';
    margin: 0 0 10px;
  }

  .modal-img {
    text-align: center;
    position: relative;
    .no-prize-img {
      width: 50%;
    }
    ._celebrate {
      position: absolute;
      left: 0;
      top: -20px;
      z-index: 2;
    }
  }

  .modal-details {
    font-size: 1.2rem;
    color: $fill-grey-font;
  }

  .modal-period {
    color: $fill-blue;
    margin: 10px auto 5px;
  }

  button {
    border: none;
    margin: 6px auto 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -16px;
    max-width: 315px;
    width: 100%;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    font-family: "db_heaventmed", sans-serif;
    padding: 14px;
    p {
      color: $fill-white;
    }
    &.btn-round {
      // min-width: 180px;
      background-color: $fill-white;
      box-shadow: $shadow;
      width:  140px;
      height: auto;
      padding: 14px 16px;
      margin: 20px auto 0;
      border-radius: 10px;
      position: static;
      font-size: 1.4rem;
    
      &._blue {
        background-color: #007bfe;
        color: #fff;
      }
    
      &._grey {
        background-color: #878787;
        color: #000;
      }
    }
  }
}

@media (max-width: 320px) {
  // .App {
  //   .jigsaw-box {
  //     .container {
  //       .item {
  //         img {
  //           width: 44%;
  //         }
  //       }
  //     }
  //   } 
  // }

  ._modal {
    .modal-img { 
      img {
        width: 80%;
      }
    }
  }
}


